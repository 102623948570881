import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Link from '~components/Link';
import Carrot from '~images/buttons/carrot.inline.svg';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  mobileNav: {
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
      top: '50px',
      width: '100%',
      backgroundColor: '#000000',
      zIndex: 50
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '10px',
    padding: '48px 0px 64px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 0px'
    }
  },
  mobileLink: {
    color: '#FFFFFF',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '600',
    lineHeight: '33px',
    '& > svg': {
      rotate: '180deg',
      marginRight: 12
    }
  }
}));

const MobileCategoryTopNavigation = ({ links }) => {
  const classes = useStyles();
  const lastNonActiveLink = useMemo(() => {
    const allNonActiveLinks = links.filter(link => !link.isActive);
    return allNonActiveLinks[allNonActiveLinks.length - 1];
  }, [links]);

  return (
    <nav className={classes.mobileNav}>
      <Container>
        <div className={classes.root}>
          {lastNonActiveLink && (
            <Link to={lastNonActiveLink.url} className={classes.mobileLink} underline="none">
              <Carrot stroke="#FFFFFF" rotate="180deg" />
              {lastNonActiveLink.mobileTitle}
            </Link>
          )}
        </div>
      </Container>
    </nav>
  );
};

MobileCategoryTopNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      mobileTitle: PropTypes.string,
      url: PropTypes.string,
      isActive: PropTypes.bool
    })
  ).isRequired
};

MobileCategoryTopNavigation.defaultProps = {};

export default MobileCategoryTopNavigation;
