import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import InfoButtonWithDialog from '~src/components/InfoButtonWithDialog';
import RichText from '~components/RichText';

const useStyles = makeStyles(theme => ({
  infoIconArea: {
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start'
    }
  },
  description: {
    marginTop: '5px',
    maxWidth: '100%',
    opacity: 0.85,
    zIndex: -1,
    '& > *': {
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    '& > p:empty': {
      height: '24px',
      [theme.breakpoints.down('xs')]: {
        height: '21px'
      }
    }
  }
}));

const Description = ({ title, description, isMobileView }) => {
  const classes = useStyles();

  if (!description?.html) {
    return null;
  }

  if (isMobileView) {
    return (
      <RichText
        html={description && description.html}
        externalClassName={classes.description}
        verticalSpacing={0}
      />
    );
  }

  return (
    <div className={classes.infoIconArea}>
      <InfoButtonWithDialog title={title} description={description} />
    </div>
  );
};

Description.propTypes = {
  title: PropTypes.string,
  isMobileView: PropTypes.bool,
  description: PropTypes.shape({ html: PropTypes.string })
};

Description.defaultProps = {
  title: '',
  description: null,
  isMobileView: false
};

export default Description;
