import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import Link from '~components/Link';
import MobileDescriptionText from './MobileDescriptionText';
import MobileDescriptionDialog from './MobileDescriptionDialog';

const useStyles = makeStyles({
  coverArea: {
    position: 'relative',
    width: '100%',
    minHeight: '200px',
    backgroundColor: 'black'
  },
  coverImage: {
    width: '100%',
    height: 'auto'
  },
  textArea: {
    position: 'absolute',
    bottom: 30,
    color: '#FFFFFF'
  },
  title: {
    color: 'white',
    fontSize: 40,
    fontFamily: 'Inter',
    fontWeight: '700',
    marginTop: 0,
    marginBottom: 9
  },
  bottomShaper: {
    position: 'absolute',
    bottom: -2,
    height: 20,
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40
  },
  specialLinkContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: 8
  },
  link: {
    color: '#009FFF',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const CategoryMobileCover = ({ title, description, coverImage, specialLinks }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const onExpandClick = () => {
    setShowDialog(true);
  };

  const onCloseDescriptionDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      {showDialog && (
        <MobileDescriptionDialog
          title={title}
          description={description}
          onClose={onCloseDescriptionDialog}
        />
      )}
      <div className={classes.coverArea}>
        <div className={classes.textArea}>
          <Container>
            <h1 className={classes.title}>{title}</h1>
            {description?.html && (
              <MobileDescriptionText
                html={description?.html}
                maxLines={2}
                onExpandClick={onExpandClick}
              />
            )}
            {specialLinks?.length > 0 && (
              <div className={classes.specialLinkContainer}>
                {specialLinks.map(link => (
                  <Link key={link?.title} to={link?.url} className={classes.link} underline="none">
                    {link?.title}
                  </Link>
                ))}
              </div>
            )}
          </Container>
        </div>
        <div className={classes.bottomShaper} />
        {coverImage?.url ? (
          <img src={coverImage.url} alt={coverImage.alt} className={classes.coverImage} />
        ) : null}
      </div>
    </>
  );
};

CategoryMobileCover.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  }),
  specialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ),
  coverImage: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

CategoryMobileCover.defaultProps = {
  description: null,
  coverImage: null,
  specialLinks: []
};

export default CategoryMobileCover;
