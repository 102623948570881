import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import Subcategory from '../Subcategory';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const SubcategoriesMobile = ({ categories: selectedCategories }) => {
  const classes = useStyles();
  const categoryRefs = useRef({});
  const hasObserversInitialized = useRef(false);
  const updateCurrentCategoryUid = useCategoryOverviewStore(
    state => state.updateCurrentCategoryUid
  );

  const firstCategoryUid = selectedCategories?.[0]?.uid;

  // Intersection Observer to track which category is in view
  useEffect(() => {
    let observer = null;
    if (typeof window !== 'undefined') {
      // determining the intersection point in the viewport
      const intersectionStart = 218; // 213 is the starting position of the first category, 5 more pixels included for better UX
      const intersectionEnd = window.innerHeight - intersectionStart; // end as the same distance from the bottom of the viewport
      observer = new window.IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const uid = entry.target.getAttribute('data-uid');
              if (uid !== firstCategoryUid) {
                // we set the observer logic to run after user has scrolled past the first category
                hasObserversInitialized.current = true;
              }

              if (hasObserversInitialized.current) {
                updateCurrentCategoryUid(uid);
              }
            }
          });
        },
        { rootMargin: `-${intersectionStart}px 0px -${intersectionEnd}px 0px` }
      );

      Object.values(categoryRefs.current).forEach(ref => {
        if (ref) observer.observe(ref);
      });
    }

    return () => {
      if (observer) {
        Object.values(categoryRefs.current).forEach(ref => {
          if (ref) observer.unobserve(ref);
        });
      }
      hasObserversInitialized.current = false;
    };
  }, [updateCurrentCategoryUid, categoryRefs, firstCategoryUid]);

  const refSetter = uid => ref => {
    categoryRefs.current[uid] = ref;
  };

  if (!selectedCategories?.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      {selectedCategories.map(selectedCategory => (
        <div
          key={selectedCategory?.uid}
          data-uid={selectedCategory?.uid}
          ref={refSetter(selectedCategory?.uid)}
        >
          <Subcategory isMobileView {...selectedCategory} />
        </div>
      ))}
    </div>
  );
};

SubcategoriesMobile.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      }),
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string,
          url: PropTypes.string,
          isSubscriberResource: PropTypes.bool,
          urlTarget: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
          resourceMedium: PropTypes.string.isRequired,
          duration: PropTypes.string,
          episodeCountText: PropTypes.string,
          metaText: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string
          })
        })
      )
    })
  ).isRequired
};

export default SubcategoriesMobile;
