import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Dialog, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RichText from '~src/components/RichText';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    padding: '14px 21px 48px 21px'
  },
  buttonStyle: {
    right: '20px',
    top: '20px',
    borderRadius: '50%',
    color: '#FFFFFF',
    backgroundColor: '#282828',
    padding: '8px',
    zIndex: 1,
    '&:hover': {
      color: '#282828',
      backgroundColor: '#F4F4F4'
    }
  },
  iconStyle: {
    fontSize: '14px'
  },
  name: {
    fontSize: '24px',
    fontFamily: 'Inter',
    lineHeight: '32px',
    fontWeight: 700,
    marginBlockStart: '8px',
    marginBlockEnd: '0px'
  },
  infoButton: {
    cursor: 'pointer',
    height: '24px'
  },
  position: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '21px',
    marginBlockStart: '2px',
    marginBlockEnd: '16px'
  },
  description: {
    marginTop: '12px',
    maxWidth: '100%',
    '& > *': {
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '21px'
    },
    '& > p:empty': {
      height: '21px'
    }
  }
});

const MobileDescriptionDialog = ({ title, description, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: '30px' }
      }}
    >
      <div className={classes.header}>
        <IconButton aria-label="close" className={classes.buttonStyle} onClick={onClose}>
          <CloseIcon className={classes.iconStyle} />
        </IconButton>
      </div>
      <div className={classes.body}>
        <Typography className={classes.name}>{title}</Typography>
        <RichText
          html={description && description.html}
          externalClassName={classes.description}
          verticalSpacing={0}
        />
      </div>
    </Dialog>
  );
};

MobileDescriptionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string,
    text: PropTypes.string
  })
};

MobileDescriptionDialog.defaultProps = {
  description: null
};

export default MobileDescriptionDialog;
