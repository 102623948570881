import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import useCategoryOverviewStore from '~hooks/store/useCategoryOverviewStore';
import Link from '~components/Link';
import clsx from '~styles/clsx';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    marginLeft: -16
  },
  scrollableContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    gap: 8,
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '& > a:first-child': {
      marginLeft: 16
    },
    '& > a:last-child': {
      marginRight: 48
    }
  },
  navItem: {
    color: '#000000',
    backgroundColor: '#F2F2F2',
    padding: '8px 15px 6px',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '500',
    borderRadius: '30px'
  },
  selectedNavItem: {
    border: '1px black solid'
  },
  parentItemsContainer: {
    marginBottom: 14,
    gap: '16px !important'
  },
  navParentItem: {
    color: '#B5B5B5',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '600'
  },
  selectedNavParentItem: {
    color: '#000000 !important'
  },
  overlay: {
    position: 'absolute',
    top: -12,
    right: -12,
    width: '110px',
    height: '80px',
    background: 'linear-gradient(to right, transparent, white)',
    pointerEvents: 'none',
    zIndex: 10
  }
}));

const scrollTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
  }
};

const MobileNavigation = ({ categories, pagePath }) => {
  const classes = useStyles();
  const scrollableParentContainerRef = useRef(null);
  const scrollableChildContainerRef = useRef(null);

  const parentNavigationItems = useMemo(() => {
    const parentMap = categories.reduce((res, category) => {
      if (!category.parentCategory?.uid) {
        const childCategories = categories.filter(cat => cat.parentCategory?.uid === category.uid);
        res[category.uid] = {
          uid: category.uid,
          title: category.navigationTitle,
          firstChildUid: childCategories.length > 0 ? childCategories[0].uid : category.uid
        };
      } else if (!res[category.parentCategory.uid]) {
        res[category.parentCategory.uid] = {
          ...category.parentCategory,
          firstChildUid: category.uid
        };
      }
      return res;
    }, {});

    return Object.values(parentMap);
  }, [categories]);

  const currentCategoryUid = useCategoryOverviewStore(state => state.currentCategoryUid);

  const selectedCategory = useMemo(() => {
    if (currentCategoryUid) {
      return categories.find(category => category.uid === currentCategoryUid);
    }
    return null;
  }, [categories, currentCategoryUid]);

  const childItems = useMemo(() => {
    if (selectedCategory?.parentCategory?.uid) {
      return categories.filter(
        category => category.parentCategory.uid === selectedCategory.parentCategory.uid
      );
    }
    return [];
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (typeof window !== 'undefined' && scrollableParentContainerRef.current) {
      // scroll parent
      const uid = selectedCategory?.parentCategory?.uid || selectedCategory?.uid;
      const selectedElement = window.document.getElementById(`browse-nav-parent-${uid}`);
      if (selectedElement) {
        scrollableParentContainerRef.current.scrollTo({
          left: selectedElement.offsetLeft - 16,
          behavior: 'smooth'
        });
      }
    }
    if (typeof window !== 'undefined' && scrollableChildContainerRef?.current) {
      // scroll child
      const childUid = selectedCategory?.uid;
      const selectedChildElement = window.document.getElementById(`browse-nav-child-${childUid}`);
      if (selectedChildElement) {
        scrollableChildContainerRef.current.scrollTo({
          left: selectedChildElement.offsetLeft - 16,
          behavior: 'smooth'
        });
      }
    }
  }, [selectedCategory?.uid, selectedCategory?.parentCategory?.uid]);

  return (
    <div className={classes.root}>
      {parentNavigationItems.length > 0 && (
        <div
          className={clsx(classes.scrollableContainer, classes.parentItemsContainer)}
          ref={scrollableParentContainerRef}
        >
          {parentNavigationItems.map(navParentItem => (
            <Link
              key={navParentItem.uid}
              id={`browse-nav-parent-${navParentItem.uid}`}
              to={`${pagePath}#${navParentItem.firstChildUid}`}
              className={clsx(
                classes.navItem,
                (selectedCategory?.uid === navParentItem.uid ||
                  selectedCategory?.parentCategory?.uid === navParentItem.uid) &&
                  classes.selectedNavItem
              )}
              onClick={scrollTop}
              underline="none"
            >
              {navParentItem?.title}
            </Link>
          ))}
        </div>
      )}
      <div className={classes.scrollableContainer} ref={scrollableChildContainerRef}>
        {childItems.map(navItem => (
          <Link
            key={navItem.uid}
            id={`browse-nav-child-${navItem.uid}`}
            to={`${pagePath}#${navItem.uid}`}
            className={clsx(
              classes.navParentItem,
              currentCategoryUid === navItem.uid && classes.selectedNavParentItem
            )}
            underline="none"
          >
            {navItem?.navigationTitle}
          </Link>
        ))}
      </div>
      <div className={classes.overlay} />
    </div>
  );
};

MobileNavigation.propTypes = {
  pagePath: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      calendarEndDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      })
    })
  ).isRequired
};

export default MobileNavigation;
