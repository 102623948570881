import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import LibraryCategoryItem from '~components/cards/LibraryCategoryItem';
import Description from './Description';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    scrollMarginTop: '213px',
    paddingTop: 10,
    paddingBottom: 10
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: 4,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 8,
      alignItems: 'flex-start'
    }
  },
  title: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: 0,
    marginBottom: 0
  },
  resourceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    marginTop: 13,
    marginBottom: 13
  }
}));

const Subcategory = ({
  uid,
  displayTitle,
  navigationTitle,
  description,
  resources,
  isMobileView
}) => {
  const classes = useStyles();
  const titleRef = useRef(null);

  return (
    <section className={classes.root} id={uid}>
      <div className={classes.titleContainer} ref={titleRef}>
        <h3 className={classes.title}>{displayTitle}</h3>
        <Description
          isMobileView={isMobileView}
          title={navigationTitle}
          description={description}
        />
      </div>
      <div className={classes.resourceContainer}>
        {resources.map(resource => (
          <LibraryCategoryItem key={`${uid}-${resource.uid}`} {...resource} />
        ))}
      </div>
    </section>
  );
};

Subcategory.propTypes = {
  uid: PropTypes.string.isRequired,
  isMobileView: PropTypes.bool,
  displayTitle: PropTypes.string.isRequired,
  navigationTitle: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  }),
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string,
      url: PropTypes.string,
      isSubscriberResource: PropTypes.bool,
      urlTarget: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      resourceMedium: PropTypes.string.isRequired,
      duration: PropTypes.string,
      episodeCountText: PropTypes.string,
      metaText: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        alt: PropTypes.string,
        url: PropTypes.string
      })
    })
  ).isRequired
};

Subcategory.defaultProps = {
  description: null,
  isMobileView: false
};

export default Subcategory;
