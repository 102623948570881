import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px'
  },
  navBarArea: {
    position: 'sticky',
    top: '114px',
    alignSelf: 'flex-start',
    width: '100%',
    backgroundColor: '#FFFFFF',
    paddingTop: 12,
    paddingBottom: 12
  },
  contentArea: {
    width: '100%'
  }
}));

const CategoryMobileLayout = ({ children, navigation }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <aside className={classes.navBarArea}>{navigation}</aside>
      <div className={classes.contentArea}>{children}</div>
    </section>
  );
};

CategoryMobileLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node.isRequired
};

export default CategoryMobileLayout;
