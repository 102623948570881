import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import useBrowseSectionPageNavigation from '~hooks/useBrowseSectionPageNavigation';
import CategoryMobileLayout from './CategoryMobileLayout';
import CategoryDesktopLayout from './CategoryDesktopLayout';
import { SubcategoriesDesktop, SubcategoriesMobile } from '../Subcategories';
import { DesktopNavigation, MobileNavigation } from '../CategoryNavigation';

const CategoryLayout = ({ categories, specialLinks, color, pagePath }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  useBrowseSectionPageNavigation(categories, pagePath);

  if (isSmallerScreen) {
    return (
      <CategoryMobileLayout
        navigation={<MobileNavigation categories={categories} pagePath={pagePath} />}
      >
        <SubcategoriesMobile categories={categories} />
      </CategoryMobileLayout>
    );
  }

  return (
    <CategoryDesktopLayout
      navigation={
        <DesktopNavigation categories={categories} color={color} specialLinks={specialLinks} />
      }
    >
      <SubcategoriesDesktop categories={categories} />
    </CategoryDesktopLayout>
  );
};

CategoryLayout.propTypes = {
  pagePath: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  specialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      navigationTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      calendarDate: PropTypes.string,
      calendarEndDate: PropTypes.string,
      parentCategory: PropTypes.shape({
        uid: PropTypes.string,
        title: PropTypes.string
      }),
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          uid: PropTypes.string,
          url: PropTypes.string,
          isSubscriberResource: PropTypes.bool,
          urlTarget: PropTypes.string,
          title: PropTypes.string,
          type: PropTypes.string,
          metaText: PropTypes.string,
          description: PropTypes.string,
          image: PropTypes.shape({
            alt: PropTypes.string,
            url: PropTypes.string
          })
        })
      )
    })
  ).isRequired
};

export default CategoryLayout;
