import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from '~styles/clsx';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '48px'
  },
  navBarArea: {
    position: 'sticky',
    top: '213px',
    alignSelf: 'flex-start',
    maxWidth: '196px',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'calc(100vh - 213px)'
  },
  contentArea: {
    width: '100%'
  }
}));

const CategoryDesktopLayout = ({ children, navigation }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <aside className={clsx(classes.navBarArea, 'hide-scrollbar')}>{navigation}</aside>
      <div className={classes.contentArea}>{children}</div>
    </section>
  );
};

CategoryDesktopLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navigation: PropTypes.node.isRequired
};

export default CategoryDesktopLayout;
