import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import RichText from '~components/RichText';
import InfoCircle from './info-circle.inline.svg';
import ClickableText from '../buttons/ClickableText';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  body: {
    padding: '0px 60px 80px 60px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 21px 80px 21px'
    }
  },
  buttonStyle: {
    right: '20px',
    top: '20px',
    borderRadius: '50%',
    color: '#FFFFFF',
    backgroundColor: '#282828',
    padding: '8px',
    zIndex: 1,
    '&:hover': {
      color: '#282828',
      backgroundColor: '#F4F4F4'
    }
  },
  iconStyle: {
    fontSize: '14px'
  },
  name: {
    fontSize: '52px',
    fontFamily: 'Inter',
    lineHeight: '64px',
    marginBlockStart: '8px',
    marginBlockEnd: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '32px'
    }
  },
  infoButton: {
    cursor: 'pointer',
    height: '24px'
  },
  position: {
    fontSize: '18px',
    fontFamily: 'Inter',
    lineHeight: '23px',
    marginBlockStart: '2px',
    marginBlockEnd: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  description: {
    marginTop: '27px',
    maxWidth: '100%',
    '& > *': {
      margin: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        lineHeight: '21px'
      }
    },
    '& > p:empty': {
      height: '24px',
      [theme.breakpoints.down('xs')]: {
        height: '21px'
      }
    }
  }
}));

const InfoButtonWithDialog = ({ title, description }) => {
  const classes = useStyles();

  const [showDialog, setShowDialog] = useState(false);

  const onCloseDescriptionDialog = () => setShowDialog(false);

  const onInfoButtonClick = () => {
    setShowDialog(true);
  };

  return (
    <>
      {showDialog && (
        <Dialog
          onClose={onCloseDescriptionDialog}
          aria-labelledby="simple-dialog-title"
          open
          maxWidth="md"
          PaperProps={{
            style: { borderRadius: '30px' }
          }}
        >
          <div className={classes.header}>
            <IconButton
              aria-label="close"
              className={classes.buttonStyle}
              onClick={onCloseDescriptionDialog}
            >
              <CloseIcon className={classes.iconStyle} />
            </IconButton>
          </div>
          <div className={classes.body}>
            <Typography className={classes.name}>{title}</Typography>
            <RichText
              html={description && description.html}
              externalClassName={classes.description}
              verticalSpacing={0}
            />
          </div>
        </Dialog>
      )}

      {description?.html && (
        <ClickableText component="div" onClick={onInfoButtonClick} className={classes.infoButton}>
          <InfoCircle />
        </ClickableText>
      )}
    </>
  );
};

InfoButtonWithDialog.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string
  }).isRequired
};

export default InfoButtonWithDialog;
